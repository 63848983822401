/*
 * Component: Table
 * ----------------
 */

.table {
  //Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 1px solid @box-border-color;
      }
    }
  }
}
  //thead cells
  > thead > tr > th {
    border-bottom: 2px solid @box-border-color;
  }
  //progress bars in tables
  tr td .progress {
    margin-top: 5px;
  }

//Bordered Table
.table-bordered {
  border: 1px solid @box-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid @box-border-color;
        background: #EAE9E9;
        text-align: left;
      }
    }
  }
  th{
        background-color: #c2c2c2;
        white-space: nowrap;
    }
  > tbody {
   > tr:nth-child(odd) {
    > td
     {
      background-color: #fff;
    }
  }
  > tr:nth-child(even) {
    > td,
    > th {
      background-color: #f9f9f9;
    }
  }
}
}
.table.no-border {
  &,
  td,
  th {
    border: 0;
  }
}

/* .text-center in tables */
table.text-center {
  &, td, th {
    text-align: center;
  }
}

.table.align {
  th {
    text-align: left;
  }
  td {
    text-align: right;
  }
}